/**
 * @author Irakli Kandelaki
 */

/**
 * @summary Lerp function, provide starting and ending point of an element and lerp amount.
 */

export const lerpFn = (start: number, end: number, amt: number): number => {
    return (1 - amt) * start + amt * end;
};