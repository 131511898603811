/**
 * @author Luka Tchigladze
 */

/**
 * @summary Cursor Follow function, that takes element on which it needs to be initiated and another element that must follow cursor.
 */

import { lerpFn } from "./lerpFn";
import { handleResize } from "@/composables/resizeHandler";

interface satelliteInterface {
    x: number;
    y: number;
    width: number;
    height: number;
    update(): void;
};

interface offsets {
    top?: number;
    right?: number;
    left?: number;
    bottom?: number;
}

export interface cursorFollowInterface {
    move(): void;
    addMouseEvent(): void;
    removeMouseEvent(): void;
};

const { windowWidth, windowHeight, windowYOffset } = handleResize();

export const cursorFollow = (el: HTMLElement, cursorEl: HTMLElement, boxEl: HTMLElement = null, offsets: offsets) => {
    let elHeight = el.getBoundingClientRect().height;
    let leftSpace = el.getBoundingClientRect().left + 43.5;
    let leftOffset = boxEl ? boxEl.getBoundingClientRect().width + 43.5 : 43.5;
    let rightOffset = el.getBoundingClientRect().right - 43.5;

    const satellite: satelliteInterface = {
        x: windowWidth.value / 2,
        y: windowHeight.value / 2,
        width: 87,
        height: 87,
        update: function () {
            let topOffset = windowYOffset.value + el.getBoundingClientRect().top + 43.5;
            let bottomOffsetSmall = boxEl ? windowYOffset.value + boxEl.getBoundingClientRect().top - 43.5 : el.getBoundingClientRect().height - 43.5;
            let bottomOffset = elHeight + el.getBoundingClientRect().top - (43.5 + (offsets.bottom ? offsets.bottom : 0));

            let l = this.x - this.width / 2;
            let t = this.y - this.height / 2;

            if (
                (this.x < leftOffset && this.x > leftSpace && this.y > topOffset && this.y < bottomOffsetSmall) ||
                (this.x > leftOffset && this.x < rightOffset && this.y > topOffset && this.y < bottomOffset)
            ) {
                cursorEl.classList.add("visible");
            } else {
                cursorEl.classList.remove("visible");
            }

            cursorEl.style.transform = `translate3d(${l}px, ${t}px, 0)`;
        },
    };

    let mouseX: number = windowWidth.value / 2;
    let mouseY: number = windowHeight.value / 2;

    const move = (): void => {
        satellite.x = lerpFn(satellite.x, mouseX, 0.1);
        satellite.y = lerpFn(satellite.y, mouseY, 0.1);

        satellite.update();
    };

    const mouseMove = (e: MouseEvent) => {
        mouseX = e.clientX;
        mouseY = e.clientY;
    };

    const addMouseEvent = (): void => {
        el.onmousemove = mouseMove;
    };

    const removeMouseEvent = (): void => {
        el.onmousemove = null;
    };

    return {
        move,
        addMouseEvent,
        removeMouseEvent,
    };
};
